<script setup lang="ts">
import type { IntegrationLink } from '@respell/database';
import integrations from '@respell/integrations';
import AppLinkedAccount from '~/components/app/AppLinkedAccount.vue';
import { useIntegrationStore } from '~/stores/integrations';

const spellStore = useSpellsStore();
const { spell } = storeToRefs(spellStore);
const integrationStore = useIntegrationStore();
const { linkedAccounts } = storeToRefs(integrationStore);

const availableAccount = computed(() =>
  linkedAccounts.value?.find(
    (account: IntegrationLink) => account.service === 'slack',
  ),
);

const isConnected = computed(() => !!availableAccount.value);
const reconnectNeeded = computed(() => !availableAccount.value?.accountId);

const inSlack = computed({
  get() {
    return spell.value?.inSlack || false;
  },
  set(value) {
    spellStore.updateSpell(spell.value?.id, { inSlack: value });
  },
});
</script>

<template>
  <NuxtLayout name="spell-tab">
    <span class="flex flex-col items-start">
      <p class="title">Slack</p>
      <p class="body dimmed">Access your spell via slack commands.</p>
    </span>
    <div
      class="border-container mt-4 p-4 bg-white w-full max-w-2xl gap-8 flex flex-col rounded-2xl items-start"
    >
      <b v-if="isConnected && reconnectNeeded" class="text-red-500">
        Please reconnect your slack account to enable this feature.
      </b>

      <UFormGroup label="1. Connect your account" class="w-full">
        <AppLinkedAccount :details="integrations['slack']" />
      </UFormGroup>

      <UFormGroup
        label="2. Enable this spell for Slack"
        class="w-full"
        description="   By enabling this spell in Slack, you can seamlessly integrate your
        workflows and enhance productivity. This feature allows you to receive
        notifications, execute commands, and interact with your spell directly
        from your Slack workspace. Experience the magic of automation and
        collaboration like never before!"
      >
        <UTooltip
          text="Connect your Slack account before enabling"
          :prevent="isConnected && !reconnectNeeded"
        >
          <UToggle
            v-model="inSlack"
            color="primary"
            size="lg"
            :disabled="!isConnected || reconnectNeeded"
          />
        </UTooltip>
      </UFormGroup>

      <UFormGroup label="3. Start automating!" class="w-full">
        <iframe
          width="640"
          height="360"
          src="https://www.loom.com/embed/ae482aaf2da947fb861324d1f379bbf7?sid=d173b692-7bfa-40dd-8373-507d9cb882fb"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        />
      </UFormGroup>
    </div>
  </NuxtLayout>
</template>
